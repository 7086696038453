/* eslint-disable max-len */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { classNames, getBlogHrefByLocale, isMobileOS, toggleChat } from 'utils';
import BigButton from 'components/Button';
import Icon from 'components/Icon';
import { useSelector, useDispatch } from 'react-redux';
import { types as themeTypes } from 'redux/ducks/settings';
import menuItems from 'constants/menu';
import { analyticsEvent } from 'services/firebase/analytics';
import { useTranslation } from 'react-i18next';
import MenuDropdown from './MenuDropdown';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.settings.theme);
  const [renderkey, setRenderkey] = useState(1);
  const language = useSelector((state) => state.settings.language);

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [location]);

  useEffect(() => {
    setRenderkey(renderkey + 1);
  }, [language]);

  return (
    <nav
      key={`navbar-${renderkey}`}
      className={`
        flex items-center justify-between flex-wrap
        bg-${theme.classes.panels}
        py-6 px-6 lg:px-32 shadow-xl mb-5 w-full fixed z-40
      `}
    >
      {isMobileOS() ? (
        <Link
          to="/"
          className={`text-${theme.classes.primary} font-semibold text-xl tracking-tight flex flex-row justify-center items-center`}
        >
          <img
            className="rounded-full z-10 mr-3 w-12"
            src="/img/hackerman.png"
            alt="Gustavo Kuze desenho hackerman"
          />
          Gustavo Kuze
        </Link>
      ) : (
        <Icon
          containerClass="mr-6"
          color={`text-${theme.classes.primary}`}
          size={10}
          icon="code"
        >
          <Link to="/" className="font-semibold text-2xl tracking-tight pl-3">
            Gustavo Kuze
          </Link>
        </Icon>
      )}

      <div className="block lg:hidden">
        <button
          type="button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className={`
            text-${theme.classes.primary}
            flex items-center
            rounded
            px-3 py-2
            focus:outline-none
          `}
        >
          <Icon
            color={`
              text-${
                theme.themeName === 'dark'
                  ? theme.classes.whiteyPrimary
                  : theme.classes.primaryStrong
              }
              hover:text-${
                theme.themeName === 'dark'
                  ? theme.classes.primaryStrong
                  : theme.classes.whiteyPrimary
              }
            `}
            size={8}
            icon={isMenuOpen ? 'close' : 'menu'}
          />
        </button>
      </div>
      <div
        className={`
          ${isMenuOpen ? '' : 'hidden'}
          w-full block flex-grow lg:flex
          lg:items-center lg:w-auto
        `}
      >
        <div
          className={`
            text-md lg:flex-grow flex
            lg:justify-end flex-col lg:flex-row
            items-center
          `}
        >
          {menuItems
            .filter(({ items, hideInNavbar }) => !items && !hideInNavbar)
            .map(({ label, action, href }) => (
              <>
                {href ? (
                  <a
                    key={label}
                    href={getBlogHrefByLocale(href, language)}
                    className={classNames(
                      'block mt-4 lg:inline-block lg:mt-0 text-md lg:text-lg',
                      location.pathname === action
                        ? `text-${theme.classes.primary}`
                        : `text-${theme.classes.paragraph}`,
                      `hover:text-${theme.classes.menuHover}`,
                      'mr-4',
                    )}
                  >
                    {t(label)}
                  </a>
                ) : (
                  <Link
                    key={label}
                    onClick={() => setIsMenuOpen(false)}
                    to={action}
                    className={classNames(
                      'block mt-4 lg:inline-block lg:mt-0 text-md lg:text-lg',
                      location.pathname === action
                        ? `text-${theme.classes.primary}`
                        : `text-${theme.classes.paragraph}`,
                      `hover:text-${theme.classes.menuHover}`,
                      'mr-4',
                    )}
                  >
                    {t(label)}
                  </Link>
                )}
              </>
            ))}

          {menuItems
            .filter(({ items }) => !!items)
            .map(({ label, items }) => (
              <MenuDropdown key={label} label={t(label)} items={items} />
            ))}
          <div>
            <BigButton
              onClick={() => {
                analyticsEvent('click_contact_navbar');
                toggleChat();
              }}
              className="ml-0 mt-5 lg:mt-0 lg:ml-20"
            >
              {t('getInTouch')}
            </BigButton>
          </div>
          <button
            type="button"
            onClick={() => {
              analyticsEvent('click_toggle_theme_navbar');
              dispatch({
                type: themeTypes.SET_THEME,
                payload: theme.themeName === 'dark' ? 'light' : 'dark',
              });
            }}
            className="focus:outline-none"
          >
            <Icon
              color={`
                mt-5 mb-3 lg:mt-0 lg:mb-0 lg:ml-5
                text-${
                  theme.themeName === 'dark'
                    ? theme.classes.whiteyPrimary
                    : theme.classes.primaryStrong
                }
                hover:text-${
                  theme.themeName === 'dark'
                    ? theme.classes.primaryStrong
                    : theme.classes.whiteyPrimary
                }
              `}
              size={8}
              icon={theme.themeName === 'dark' ? 'moon' : 'light'}
            />
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch({
                type: themeTypes.SET_LANGUAGE,
                payload: language !== 'pt-BR' ? 'pt-BR' : 'en',
              });

              analyticsEvent('click_toggle_locale_navbar');
            }}
            className={`focus:outline-none lg:ml-5 text-${
              theme.themeName === 'dark'
                ? theme.classes.whiteyPrimary
                : theme.classes.primaryStrong
            }
            hover:text-${
              theme.themeName === 'dark'
                ? theme.classes.primaryStrong
                : theme.classes.whiteyPrimary
            }
            `}
          >
            {language !== 'pt-BR' ? `Português` : `English`}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
