const theme = {
  light: {
    colors: {
      primary: '#52158f',
      primaryStrong: '#553c9a',
      primaryStronger: '#44337a',
      whiteyPrimary: '#e9d8fd',
      whitierPrimary: '#faf5ff',
      secondary: '#ecc94b',
      secondaryStrong: '#b1945b',
      secondaryStronger: '#b1945b',
      panels: '#ffffff',
      background: '#551695',
      gradient: {
        from: '#3d0059',
        to: '#551695',
      },
      sectionTitle: '#2d3748',
      boxShadow: '0px 1px 10px 4px #ddd',
      boxShadowHover: '0px 1px 10px 1px #ddd',
      secondaryBoxShadow: '0px 1px 10px 4px #ddd',
      secondaryBoxShadowHover: '0px 1px 10px 1px #ddd',
    },
    classes: {
      primary: 'purple-500',
      primaryStrong: 'purple-800',
      primaryStronger: 'purple-900',
      whiteyPrimary: 'purple-200',
      whitierPrimary: 'purple-100',
      secondary: 'yellow-500',
      secondaryStrong: 'yellow-600',
      secondaryStronger: 'yellow-900',
      panels: 'white',
      background: 'purple-500',
      sectionTitle: 'gray-800',
      sectionCaption: 'gray-600',
      paragraph: 'gray-900',
      menuHover: 'purple-600',
      featuredPanel1: 'red-100',
      featuredPanel2: 'blue-100',
      footerBottomBackground: 'gray-200',
    },
  },
  dark: {
    colors: {
      primary: '#52158f',
      primaryStrong: '#553c9a',
      primaryStronger: '#44337a',
      whiteyPrimary: '#e9d8fd',
      whitierPrimary: '#faf5ff',
      secondary: '#ecc94b',
      secondaryStrong: '#d69e2e',
      secondaryStronger: '#744210',
      panels: '#1a202c',
      background: '#551695',
      gradient: {
        from: '#3d0059',
        to: '#551695',
      },
      sectionTitle: '#2d3748',
      boxShadow: '0px 1px 10px 4px #6b46c1',
      boxShadowHover: '0px 1px 10px 1px #6b46c1',
      secondaryBoxShadow: '0px 1px 10px 4px #4e3e1f',
      secondaryBoxShadowHover: '0px 1px 10px 1px #4e3e1f',
    },
    classes: {
      primary: 'purple-500',
      primaryStrong: 'purple-800',
      primaryStronger: 'purple-900',
      whiteyPrimary: 'purple-200',
      whitierPrimary: 'purple-100',
      secondary: 'yellow-500',
      secondaryStrong: 'yellow-600',
      secondaryStronger: 'yellow-900',
      panels: 'gray-900',
      background: 'purple-500',
      sectionTitle: 'gray-500',
      sectionCaption: 'purple-300',
      paragraph: 'gray-200',
      menuHover: 'purple-600',
      footerBottomBackground: 'gray-800',
    },
  },
};

export default theme;
