import { useState } from 'react';
import { listVideos } from 'services/youtube';

const useYoutubeVideos = () => {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const loadYoutubeVideos = async () => {
    setIsLoading(true);
    const videosList = await listVideos();
    setVideos(videosList);
    setIsLoading(false);
  };

  return [videos, loadYoutubeVideos, isLoading];
};

export default useYoutubeVideos;
