import { mapVideoToWork } from 'utils';

const listVideos = async () => {
  const result = await fetch(
    // eslint-disable-next-line max-len
    'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,status,contentDetails&maxResults=50&playlistId=PLl-VOWCsAi4oqVpP9u6cifrvYqJ-kf_-E&key=AIzaSyDlGxVlygE8QB1X1NjjgvjEanYI1XjcgvQ',
  ).then((body) => body.json());

  const mappedToWork = result.items
    .filter((video) => video.status.privacyStatus === 'public')
    .map((video) => mapVideoToWork(video))
    .reverse();

  return [...mappedToWork].reverse();
};

export { listVideos };
