/* eslint-disable max-len */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { worksTypes } from 'redux/ducks/works';
import WorksSection from 'components/WorksSection';
import Footer from 'components/Footer';
import Fade from 'react-reveal/Fade';
import useYoutubeVideos from 'hooks/useYoutubeVideos';
import { useTranslation } from 'react-i18next';

const HomeTitle = styled.h1``;

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useSelector((state) => state.settings.theme);
  const language = useSelector((state) => state.settings.language);
  const { isLoading: isLoadingWorks, works } = useSelector(
    (state) => state.works,
  );

  const [videos, loadYoutubeVideos, isLoadingVideos] = useYoutubeVideos();

  const showCompany1Projects = useSelector(
    (state) => state.settings.showCompany1Projects,
  );

  useEffect(() => {
    dispatch({
      type: worksTypes.FETCH_WORKS,
    });

    loadYoutubeVideos();
  }, []);

  return (
    <div className="h-screen pt-40">
      <Fade>
        <header className="py-4 px-12 lg:px-64 container">
          <HomeTitle
            className={`
              font-bold text-${theme.classes.whitierPrimary}
              text-4xl lg:text-6xl
            `}
          >
            {t('portfolio')}
          </HomeTitle>
          <h2
            className={`
              font-bold text-${theme.classes.whiteyPrimary}
              text-lg lg:text-2xl
            `}
          >
            {t('portfolioDescription')}
          </h2>
        </header>
      </Fade>

      <section className={`bg-${theme.classes.panels} py-8 mt-32`}>
        <div className="container max-w-5xl mx-auto m-8">
          <WorksSection
            isLoading={isLoadingWorks}
            title={t('portfolioSectionTitle1')}
            description={t('portfolioSectionDescription1')}
            works={(works.freelancer || []).filter(
              (work) => work.language === language,
            )}
          />

          {!!showCompany1Projects && (
            <WorksSection
              isLoading={isLoadingWorks}
              title={
                <a
                  href="http://lighthouseit.com.br/"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  {t('portfolioSectionTitle4')}
                </a>
              }
              description={
                <>
                  {t('portfolioSectionDescription4')}{' '}
                  <a
                    href="http://lighthouseit.com.br/"
                    target="_blank"
                    rel="noopener noreferer"
                    className="text-red-600"
                  >
                    Lighthouse
                  </a>
                </>
              }
              works={(works.lighthouse || []).filter(
                (work) => work.language === language,
              )}
              disclaimer={
                <p>
                  <strong>{t('legalDisclaimer1')}</strong>:{' '}
                  {t('legalDisclaimer2')}{' '}
                  <a
                    href="http://lighthouseit.com.br/"
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    <strong>Lighthouse</strong>
                  </a>{' '}
                  {t('legalDisclaimer3')}{' '}
                  <a
                    href="http://lighthouseit.com.br/"
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    <strong>Lighthouse</strong>
                  </a>
                  ).
                </p>
              }
              invert
            />
          )}

          <WorksSection
            isLoading={isLoadingWorks}
            title={t('portfolioSectionTitle2')}
            description={t('portfolioSectionDescription2')}
            works={(works.studies || []).filter(
              (work) => work.language === language,
            )}
            customDivider={<></>}
          />

          <WorksSection
            isLoading={isLoadingVideos}
            title={t('portfolioSectionTitle3')}
            description={t('portfolioSectionDescription3')}
            works={videos}
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
