const menu = [
  {
    label: 'Início',
    action: '/',
  },
  {
    label: 'Portfólio',
    href: 'https://blog.gustavokuze.com.br/projects',
  },
  {
    label: 'Sobre',
    href: 'https://blog.gustavokuze.com.br/about',
    // hideInFooter: true,
  },
  {
    label: 'Anotações',
    action: '/tips',
    hideInNavbar: true,
  },
  {
    label: 'Mais',
    items: [
      {
        label: 'Anotações',
        action: '/tips',
      },
      {
        label: 'Blog',
        href: 'https://blog.gustavokuze.com.br',
      },
    ],
  },
];

export default menu;
