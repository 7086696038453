import { takeLatest, all } from 'redux-saga/effects';
import { tipsTypes } from '../ducks/tips';
import { worksTypes } from '../ducks/works';

import { fetchGists } from './tipsSaga';
import { fetchWorks } from './worksSaga';

export default function* root() {
  yield all([yield takeLatest(tipsTypes.FETCH_GISTS, fetchGists)]);
  yield all([yield takeLatest(worksTypes.FETCH_WORKS, fetchWorks)]);
}
