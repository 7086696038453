export const typesPrefix = '@works';

export const worksTypes = {
  SET_WORKS: `${typesPrefix}/SET_WORKS`,
  SET_IS_LOADING: `${typesPrefix}/SET_IS_LOADING`,
  FETCH_WORKS: `${typesPrefix}/FETCH_WORKS`,
};

const INITIAL_STATE = {
  works: {},
  isLoading: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case worksTypes.SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case worksTypes.SET_WORKS:
      return { ...state, works: payload };
    default:
      return state;
  }
};

export const fetchWorksAction = () => ({
  type: worksTypes.FETCH_WORKS,
});

export const setWorksAction = (payload) => ({
  type: worksTypes.SET_WORKS,
  payload,
});
