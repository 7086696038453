/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { toggleChat } from 'utils';
import Fade from 'react-reveal/Fade';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { analyticsEvent } from 'services/firebase/analytics';
import { useTranslation } from 'react-i18next';

const Pricing = () => {
  const theme = useSelector((state) => state.settings.theme);
  const { t } = useTranslation();

  const CardItem = ({ children }) => (
    <div
      className={`
        text-center border-0 border-grey-light
        border-b border-solid py-2 xl:py-4 w-8/12
        mx-auto
        flex flex-row justify-start
      `}
    >
      <div className="flex flex-row justify-center items-center">
        <Icon svgClass="mr-3" color="text-green-500" size={4} icon="check" />
        <p className="lg:text-lg">{children}</p>
      </div>
    </div>
  );

  return (
    <>
      <div className="w-full bg-blue pt-8 mt-12 px-12 md:px-auto">
        <div className="flex flex-col sm:flex-row justify-center mb-6 sm:mb-0">
          <Fade left>
            <div
              className={`
                sm:flex-1 lg:flex-initial lg:w-1/4
                rounded-lg md:rounded-tr-none md:rounded-br-none
                bg-white mt-4 flex flex-col
              `}
            >
              <div className="p-8 text-3xl font-bold text-center">
                {t('pricingTitle1')}
              </div>
              <div
                className={`
                  border-0 border-grey-light
                  border-t border-solid text-sm mb-12
                `}
              >
                <CardItem>{t('pricingItem1-1')}</CardItem>
                <CardItem>{t('pricingItem1-2')}</CardItem>
                <CardItem>{t('pricingItem1-3')})</CardItem>
                <CardItem>{t('pricingItem1-4')})</CardItem>
                <CardItem>{t('pricingItem1-5')}</CardItem>
                <CardItem>{t('pricingItem1-6')}</CardItem>
              </div>
              <div className="text-center mb-8 mt-auto">
                <Button
                  noShadow
                  onClick={() => {
                    analyticsEvent('request_budget_site');
                    toggleChat();
                  }}
                  className="mt-12 md:mt-1 mb-3"
                  isResponsive={false}
                >
                  {t('pricingButton')}
                </Button>
              </div>
            </div>
          </Fade>
          <Fade big>
            <div
              className={`
                flex-1 lg:flex-initial lg:w-1/4
                rounded-lg md:rounded-b-none bg-white
                mt-4 sm:-mt-4 shadow-lg
                z-30 flex flex-col
              `}
            >
              <div className="w-full p-8 text-3xl font-bold text-center">
                {t('pricingTitle2')}
              </div>
              <div
                className={`
                  w-full border-0 border-grey-light
                  border-t border-solid text-sm
                `}
              >
                <p
                  className={`
                    text-${theme.classes.primaryStronger} text-lg
                    text-center mt-5
                  `}
                >
                  + {t('everythingFromSitePlus')}
                </p>
                <CardItem>{t('pricingItem2-1')}</CardItem>
                <CardItem>{t('pricingItem2-2')}</CardItem>
                <CardItem>{t('pricingItem2-3')}</CardItem>
                <CardItem>{t('pricingItem2-4')}</CardItem>
                <CardItem>{t('pricingItem2-5')}</CardItem>
                <CardItem>{t('pricingItem2-6')}</CardItem>
              </div>
              <div className="w-full text-center mb-8 mt-auto">
                <Button
                  onClick={() => {
                    analyticsEvent('request_budget_system');
                    toggleChat();
                  }}
                  className="mt-12 md:mt-1 mb-3"
                  secondary
                  noShadow
                  isResponsive={false}
                >
                  {t('pricingButton')}
                </Button>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div
              className={`
                flex-1 lg:flex-initial lg:w-1/4
                rounded-lg md:rounded-tl-none md:rounded-bl-none
                bg-white mt-4 flex flex-col
              `}
            >
              <div className="p-8 text-3xl font-bold text-center">
                {t('pricingTitle3')}
              </div>
              <div
                className={`
                  border-0 border-grey-light border-t
                  border-solid text-sm
                `}
              >
                <CardItem>{t('pricingItem3-1')}</CardItem>
                <CardItem>{t('pricingItem3-2')}</CardItem>
                <CardItem>{t('pricingItem3-3')}</CardItem>
                <CardItem>{t('pricingItem3-4')}</CardItem>
              </div>
              <div className="text-center pt-8 mb-8 mt-auto">
                <Button
                  noShadow
                  onClick={() => {
                    analyticsEvent('request_budget_app');
                    toggleChat();
                  }}
                  className="mt-12 md:mt-1 mb-3"
                  isResponsive={false}
                >
                  {t('pricingButton')}
                </Button>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default Pricing;
