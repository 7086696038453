/* eslint-disable no-console */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const useLocaleSync = () => {
  const { i18n } = useTranslation();
  const language = useSelector((state) => state.settings.language);

  useEffect(() => {
    i18n.changeLanguage(language);
    try {
      window.$chatwoot.setLocale(language === 'en' ? 'en' : 'pt_BR');
    } catch (error) {
      console.error(error);
    }
  }, [language]);
};

export { useLocaleSync };
