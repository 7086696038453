/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import React from 'react';
import Work from 'components/Work';
import { useSelector } from 'react-redux';

const WorksSection = ({
  title,
  description,
  backgroundClass,
  invert,
  customDivider,
  works = [],
  disclaimer,
  isLoading = false,
  skeletonCount = 2,
}) => {
  const theme = useSelector((state) => state.settings.theme);

  return (
    <div
      className={`
      w-full mb-4
      py-5
      ${backgroundClass}
    `}
    >
      <div
        className={`
          mt-8
          mx-auto gradient
          w-64 my-0 py-0
          rounded-t
        `}
      >
        <h2
          className={`
            text-${theme.classes.sectionCaption}
            text-2xl text-center
            mb-5
          `}
        >
          {title}
        </h2>
      </div>
      <div
        className={`
          mb-12
          mx-auto gradient
          w-10/12 md:w-6/12 py-0
          rounded-t
        `}
      >
        <h3
          className={`
            text-${theme.themeName === 'dark'
              ? theme.classes.whiteyPrimary
              : theme.classes.primaryStronger
            }
            text-xl text-center
            mb-10
          `}
        >
          {description}
        </h3>
      </div>

      {isLoading
        ? Array.from(Array(skeletonCount).keys()).map((item) => (
          <div
            style={{ backgroundColor: theme.colors.panels }}
            className={` p-2 sm:p-4 sm:h-64 rounded-2xl shadow-lg flex flex-col sm:flex-row gap-5 select-none my-4 mx-12 sm:my-8 rounded-lg`}
            key={`skeleton-${item}`}>
            <div style={{ backgroundColor: theme.colors.secondary }} className="h-52 sm:h-full w-2/4 rounded-xl animate-pulse" />
            <div className="flex flex-col flex-1 gap-5 sm:p-2">
              <div className="flex flex-1 flex-col gap-3">
                <div style={{ backgroundColor: theme.colors.whiteyPrimary }} className="w-full sm:w-64 animate-pulse h-64 sm:h-10 rounded-2xl" />
                <div style={{ backgroundColor: theme.colors.whiteyPrimary }} className="w-full animate-pulse h-3 rounded-2xl" />
                <div style={{ backgroundColor: theme.colors.whiteyPrimary }} className="w-full animate-pulse h-3 rounded-2xl" />
                <div style={{ backgroundColor: theme.colors.whiteyPrimary }} className="w-full animate-pulse h-3 rounded-2xl" />
                <div style={{ backgroundColor: theme.colors.whiteyPrimary }} className="w-full animate-pulse h-3 rounded-2xl" />
              </div>
              <div className="mt-auto flex sm:flex-row sm:justify-end gap-3">
                <div style={{ backgroundColor: theme.colors.whiteyPrimary }} className="w-32 h-12 animate-pulse rounded-full" />
                <div style={{ backgroundColor: theme.colors.secondary }} className="w-32 h-12 animate-pulse rounded-full" />
              </div>
            </div>
          </div>
        ))
        : works.map((work, i) => (
          <Work
            key={`${work.title}-${i}`}
            work={work}
            invert={invert && works.length > 2 && i % 2 === 1}
          />
        ))}

      {disclaimer && <p className="text-xl text-red-600 m-12">{disclaimer}</p>}

      {customDivider || (
        <hr className={`mt-24 text-${theme.classes.whiteyPrimary}`} />
      )}
    </div>
  );
};

export default WorksSection;
