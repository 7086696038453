import Icon from 'components/Icon';
import React from 'react';
import { useSelector } from 'react-redux';
import { classNames } from 'utils';

const Loader = ({ isLoading, loadingText, children }) => {
  const theme = useSelector((state) => state.settings.theme);

  return isLoading ? (
    <>
      <Icon
        containerClass="mr-6"
        svgClass="animate-spin mx-auto mt-12"
        color={`text-${
          theme.themeName === 'dark'
            ? theme.classes.secondary
            : theme.classes.primary
        }`}
        size={8}
        icon="loader"
      />
      <p
        className={classNames(
          `text-${
            theme.themeName === 'dark'
              ? theme.classes.secondary
              : theme.classes.primary
          }`,
          `mx-auto text-center mb-12 mt-2 pr-5`,
        )}
      >
        {loadingText || 'Carregando...'}
      </p>
    </>
  ) : (
    <>{children}</>
  );
};

export default Loader;
