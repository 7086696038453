import React from 'react';
import MainContainer from 'styles/styledComponents/MainContainer';
import './styles/tailwind-output.css';
import './styles/animations.css';
import store from 'redux/store';
import { Provider } from 'react-redux';
import { saveState } from 'redux/persist';
import SettingsInitializer from 'components/SettingsInitializer';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptBr from './locales/pt-br.json';
import en from './locales/en.json';
import Routes from './Routes';

i18n.use(initReactI18next).init({
  resources: {
    [`pt-BR`]: {
      translation: ptBr,
    },
    en: {
      translation: en,
    },
  },
  fallbackLng: 'pt-BR',
  lng: 'pt-BR',
  interpolation: {
    escapeValue: false,
  },
});

store.subscribe(() => {
  saveState({
    settings: store.getState().settings,
  });
});

const App = () => {
  return (
    <Provider store={store}>
      <SettingsInitializer />
      <MainContainer currentTheme={store.getState().settings.theme}>
        <Routes />
      </MainContainer>
    </Provider>
  );
};

export default App;
