/* eslint-disable max-len */
import { analyticsEvent } from 'services/firebase/analytics';

const classNames = (...args) => {
  return args.map((arg) => `${arg}`).join(' ');
};

export const getOSName = () => {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
};

export const isMobileOS = () => {
  const os = getOSName();
  return os === 'Android' || os === 'iOS';
};

const toggleChat = () => {
  // eslint-disable-next-line no-undef
  if (!!window.$chatwoot && !!$chatwoot.hasLoaded) {
    // eslint-disable-next-line no-undef
    $chatwoot.toggle();
  }
};

const initializeChatListeners = () => {
  const chatWidgets = document.querySelectorAll('.woot-widget-bubble');
  if (chatWidgets && chatWidgets.length > 0) {
    chatWidgets[0].addEventListener('click', () => {
      // chat was opened
      analyticsEvent('click_widget_open_chat');
    });

    chatWidgets[1].addEventListener('click', () => {
      // chat was closed
      analyticsEvent('click_widget_closed_chat');
    });
  }
};

const mapVideoToWork = (video) => ({
  title:
    video.snippet.title.length > 19
      ? `${video.snippet.title.substring(0, 16)}...`
      : video.snippet.title,
  description: video.snippet.title,
  img: video.snippet.thumbnails.high.url,
  projectLink: `https://www.youtube.com/watch?v=${video.snippet.resourceId.videoId}`,
  year: new Date(video.contentDetails.videoPublishedAt).getFullYear(),
  experiences: [
    `Vídeo publicado em: ${new Date(
      video.contentDetails.videoPublishedAt,
    ).toLocaleDateString()}`,
  ],
});

const getBlogHrefByLocale = (href, language) => {
  return `${href}`.includes('blog') && language !== 'pt-BR'
    ? `${new URL(href).origin}/${language}${new URL(href).pathname}`
    : href;
};

export {
  classNames,
  toggleChat,
  initializeChatListeners,
  mapVideoToWork,
  getBlogHrefByLocale,
};
