import firebase from './index';

firebase.database();

const getSettings = async () => {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref('/settings')
      .once('value')
      .then((snapshot) =>
        snapshot && snapshot.val()
          ? resolve(snapshot.val())
          : reject(new Error("can't load settings")),
      )
      .catch((err) => reject(new Error(err.message || "can't load settings")));
  });
};

const getWorks = async () => {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref('/works')
      .once('value')
      .then((snapshot) =>
        snapshot && snapshot.val()
          ? resolve(snapshot.val())
          : reject(new Error("can't load works")),
      )
      .catch((err) => reject(new Error(err.message || "can't load works")));
  });
};

export { getSettings, getWorks };
