import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyDlGxVlygE8QB1X1NjjgvjEanYI1XjcgvQ',
  authDomain: 'portfolio-7a28e.firebaseapp.com',
  databaseURL: 'https://portfolio-7a28e.firebaseio.com',
  projectId: 'portfolio-7a28e',
  storageBucket: 'portfolio-7a28e.appspot.com',
  messagingSenderId: '988985245987',
  appId: '1:988985245987:web:b6c8a16e5f5009a4cb0c96',
  measurementId: 'G-HL39BLFMW4',
};

firebase.initializeApp(config);

export default firebase;
