import { combineReducers } from 'redux';
import worksReducer from './works';
import settingsReducer from './settings';
import tipsReducer from './tips';

export default combineReducers({
  works: worksReducer,
  settings: settingsReducer,
  tips: tipsReducer,
});
