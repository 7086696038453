import theme from 'styles/theme';

export const typesPrefix = '@settings';

export const types = {
  SET_THEME: `${typesPrefix}/SET_THEME`,
  SET_LANGUAGE: `${typesPrefix}/SET_LANGUAGE`,
  SET_SHOW_COMPANY1_PROJECTS: `${typesPrefix}/SET_SHOW_COMPANY1_PROJECTS`,
};

const INITIAL_STATE = {
  theme: {
    ...theme.dark,
    themeName: 'dark',
  },
  showCompany1Projects: false,
  language: 'pt-BR',
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.SET_THEME:
      return { ...state, theme: { ...theme[payload], themeName: payload } };
    case types.SET_LANGUAGE:
      return { ...state, language: payload };
    case types.SET_SHOW_COMPANY1_PROJECTS:
      return {
        ...state,
        showCompany1Projects: payload,
      };
    default:
      return state;
  }
};

export const setTheme = (payload) => ({
  type: types.SET_THEME,
  payload,
});

export const setLanguage = (payload) => ({
  type: types.SET_LANGUAGE,
  payload,
});

export const setShowCompany1Projects = (payload) => ({
  type: types.SET_SHOW_COMPANY1_PROJECTS,
  payload,
});
