import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSettings } from 'services/firebase/db';
import { types } from 'redux/ducks/settings';
import { loadState } from 'redux/persist';
import { initializeChatListeners } from 'utils';
import { useLocaleSync } from 'hooks/useLocaleSync';

const SettingsInitializer = () => {
  const dispatch = useDispatch();
  useLocaleSync();

  const setShowCompany1Projects = (show) => {
    dispatch({
      type: types.SET_SHOW_COMPANY1_PROJECTS,
      payload: show || false,
    });
  };

  const setDefaultTheme = (theme) => {
    dispatch({
      type: types.SET_THEME,
      payload: theme || 'dark',
    });
  };

  /**
   * workaround: if "else" is used here,
   * the portfolio is not affected by the remote settings
   * DO NOT refactor to if-else!
   */
  const loadSettings = async () => {
    const isStatePersisted = loadState();

    if (isStatePersisted) {
      const remoteSettings = await getSettings();
      if (remoteSettings) {
        setShowCompany1Projects(remoteSettings.show_company1_projects);
      }
    }

    if (!isStatePersisted) {
      const remoteSettings = await getSettings();
      if (remoteSettings) {
        setDefaultTheme(remoteSettings.default_theme);
        setShowCompany1Projects(remoteSettings.show_company1_projects);
      }
    }

    initializeChatListeners();
  };

  useEffect(() => {
    loadSettings();
  }, []);

  return <></>;
};

export default SettingsInitializer;
