/* eslint-disable max-len */
import Footer from 'components/Footer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { analyticsEvent } from 'services/firebase/analytics';

const About = () => {
  const theme = useSelector((state) => state.settings.theme);
  const { t } = useTranslation();

  const AboutSection = ({ title, items = [] }) => (
    <div className="mx-12 lg:mx-64">
      <h3
        className={`
          text-${theme.classes.sectionTitle}
          text-3xl lg:text-4xl mb-8
        `}
      >
        {title}
      </h3>
      {!!items.length && (
        <ul className="mb-8">
          {items.map(({ label, description }) => (
            <li
              className={`
                mb-8 list-disc
                text-${theme.classes.whiteyPrimary}
              `}
            >
              <p
                className={`
                  text-${theme.classes.paragraph}
                  text-lg lg:text-2xl
                `}
              >
                <strong>{label}</strong> – {description}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <div className="h-screen pt-40">
      <header
        className={`
          py-4 px-12 lg:px-64
          flex flex-col
          items-center justify-center w-full
        `}
      >
        <h1
          className={`
            font-bold text-${theme.classes.whitierPrimary}
            text-3xl lg:text-5xl
          `}
        >
          {t('aboutTitle')}
        </h1>

        <div
          className={`
            flex flex-row
            items-center justify-center w-full
          `}
        >
          <Fade>
            <img
              src="/img/gustavo_kuze.jpg"
              alt="Minha foto"
              className="rounded-full w-64 lg:w-1/4 height-64 my-8"
            />
          </Fade>
        </div>

        <Fade>
          <h2
            className={`
              font-bold text-${theme.classes.whiteyPrimary}
              text-lg lg:text-xl text-center
              max-w-lg
            `}
          >
            {t('aboutDescription1')} <em>{t('aboutDescription2')}</em>{' '}
            {t('aboutDescription3')}
            <br />
            <em>{t('aboutDescription4')}</em> {t('aboutDescription5')}{' '}
          </h2>
        </Fade>
      </header>

      <p className={`text-center mt-32 text-${theme.classes.whitierPrimary}`}>
        {t('aboutDescription6')}
      </p>
      <p className="text-center mt-12 text-lg shake-vertical">
        <span role="img" aria-label="apontando para baixo" className="text-3xl">
          👇
        </span>
      </p>

      <section className={`bg-${theme.classes.panels} py-12 mt-32`}>
        <AboutSection
          title={t('experiencesTitle')}
          items={[
            {
              label: t('experience1Title'),
              description: t('experience1Description'),
            },
            {
              label: t('experience2Title'),
              description: t('experience2Description'),
            },
            {
              label: t('experience3Title'),
              description: t('experience3Description'),
            },
            {
              label: t('experience4Title'),
              description: t('experience4Description'),
            },
            {
              label: t('experience5Title'),
              description: t('experience5Description'),
            },
          ]}
        />

        <hr className="my-12" />

        <AboutSection
          title={t('academicTitle')}
          items={[
            {
              label: t('academic1Title'),
              description: t('academic1Description'),
            },
            {
              label: t('academic2Title'),
              description: t('academic2Description'),
            },
          ]}
        />

        <hr className="my-12" />

        <AboutSection
          title={t('certificationsTitle')}
          items={[
            {
              label: t('certifications1Title'),
              description: (
                <>
                  <p>{t('certifications1-1Description')}</p>
                  <a
                    href="https://www.udemy.com/certificate/UC-LQE1SKUI/"
                    target="_blank"
                    rel="noopener noreferer"
                    className="text-purple-500"
                    onClick={() => {
                      analyticsEvent('click_view_certificate_web_moderno');
                    }}
                  >
                    {t('certifications1-2Description')}
                  </a>
                </>
              ),
            },
            {
              label: t('certifications2Title'),
              description: t('certifications2Description'),
            },
          ]}
        />

        <hr className="my-12" />

        <AboutSection
          title={t('languagesTitle')}
          items={[
            {
              label: t('languages1Title'),
              description: t('languages1Description'),
            },
            {
              label: t('languages2Title'),
              description: t('languages2Description'),
            },
          ]}
        />
      </section>
      <Footer />
    </div>
  );
};

export default About;
