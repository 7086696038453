import React from 'react';
import mapsImg from 'assets/imgs/maps.svg';
import pwaImg from 'assets/imgs/pwa.svg';
import responsiveImg from 'assets/imgs/responsive.svg';
import Fade from 'react-reveal/Fade';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const WhatICanDo = () => {
  const theme = useSelector((state) => state.settings.theme);
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`
          w-full xl:w-11/12 xl:mx-auto
          flex flex-col items-center
        `}
      >
        <div
          className={`
            w-full px-12 md:px-32 xl:px-64 flex flex-col lg:flex-row 
            items-center justify-between lg:justify-center
          `}
        >
          <img src={mapsImg} alt="teste" className="lg:w-6/12" />
          <div className="lg:w-5/12">
            <h3
              className={`
                text-3xl md:text-4xl
                text-${theme.classes.sectionTitle}
              `}
            >
              <Fade top cascade>
                {t('mapsIntegrationTitle')}
              </Fade>
            </h3>

            <p
              className={`
                w-full
                text-lg
                text-${theme.classes.paragraph}
              `}
            >
              <Fade>{t('mapsIntegrationDescription')}</Fade>
            </p>
          </div>
        </div>
      </div>
      <div
        className={`
          w-full xl:w-11/12 xl:mx-auto flex flex-col items-center
          my-16 lg:my-3
        `}
      >
        <div
          className={`
            w-full px-12 md:px-32 xl:px-64 flex flex-col-reverse lg:flex-row 
            items-center justify-between lg:justify-center
          `}
        >
          <div className="lg:w-5/12">
            <h3
              className={`
                w-full
                text-3xl md:text-4xl
                text-${theme.classes.sectionTitle}
              `}
            >
              <Fade top cascade>
                {t('pwaTitle')}
              </Fade>
            </h3>

            <p
              className={`
                w-full
                text-lg
                text-${theme.classes.paragraph}
              `}
            >
              <Fade>{t('pwaDescription')}</Fade>
            </p>
          </div>
          <Fade>
            <img
              src={pwaImg}
              alt="teste"
              className="lg:w-5/12 mb-12 lg:mb-2 lg:ml-12"
            />
          </Fade>
        </div>
      </div>

      <div
        className={`
          w-full xl:w-11/12 xl:mx-auto  flex flex-col items-center
          mt-16
        `}
      >
        <div
          className={`
            w-full px-12 md:px-32 xl:px-64 flex flex-col lg:flex-row 
            items-center justify-between lg:justify-center
          `}
        >
          <Fade>
            <img
              src={responsiveImg}
              alt="teste"
              className={`
                w-9/12 sm:w-6/12 lg:w-4/12
                mb-12 lg:mb-2
              `}
            />
          </Fade>
          <div className="lg:w-5/12 lg:pl-20">
            <h3
              className={`
                text-3xl md:text-4xl
                text-${theme.classes.sectionTitle}
              `}
            >
              <Fade top cascade>
                {t('responsiveDesignTitle')}
              </Fade>
            </h3>

            <p
              className={`
                w-full
                text-lg 
                text-${theme.classes.paragraph}
              `}
            >
              <Fade>{t('responsiveDesignDescription')}</Fade>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatICanDo;
