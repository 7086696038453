/* eslint-disable max-len */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import WavySection from 'components/WavySection';
import { getBlogHrefByLocale, toggleChat } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { worksTypes } from 'redux/ducks/works';
import WorksSection from 'components/WorksSection';
import Icon from 'components/Icon';
import Footer from 'components/Footer';
import Fade from 'react-reveal/Fade';
import { analyticsEvent } from 'services/firebase/analytics';
import { useTranslation } from 'react-i18next';
import WhatICanDo from './WhatICanDo';
import Pricing from './Pricing';

const HomeTitle = styled.h1``;

const Home = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.settings.theme);
  const language = useSelector((state) => state.settings.language);
  const { t } = useTranslation();
  const { isLoading: isLoadingWorks, works } = useSelector(
    (state) => state.works,
  );

  useEffect(() => {
    dispatch({
      type: worksTypes.FETCH_WORKS,
    });
  }, []);

  const isDarkTheme = () => theme.themeName === 'dark';

  return (
    <div className="h-screen pt-40">
      <Fade>
        <header className="py-4 px-12 lg:px-64 container">
          <h3
            className={`
              font-bold text-${theme.classes.whiteyPrimary}
              text-md lg:text-2xl
            `}
          >
            {t('hero.label1')}
          </h3>
          <HomeTitle
            className={`
              font-bold text-${theme.classes.whitierPrimary}
              text-4xl lg:text-6xl
            `}
          >
            {t('hero.label2')}
          </HomeTitle>
          <h2
            className={`
              font-bold text-${theme.classes.whiteyPrimary}
              text-lg lg:text-2xl
            `}
          >
            {t('hero.label3')}
          </h2>

          <Button
            onClick={() => {
              analyticsEvent('click_contact_hero');
              toggleChat();
            }}
            isResponsive={false}
            secondary={isDarkTheme()}
            noShadow={!isDarkTheme()}
            className="mt-5 flicker-in-1"
            color={
              !isDarkTheme() &&
              `
              bg-${theme.classes.secondaryStrong}
              hover:bg-${theme.classes.secondary}
              text-${theme.classes.whitierPrimary}
              hover:text-${theme.classes.primaryStrong}
              mt-5
              `
            }
          >
            {t('getInTouch')}
          </Button>
        </header>
      </Fade>

      <img
        style={{ right: '13%', top: 310 }}
        className={`
        slide-in-fwd-center
        rounded-full z-10 shadow-xl
        sm:w-1/4 sm:mt-4
        hidden sm:block
        absolute
        md:mt-0
        lg:mt-40
        xl:mt-2`}
        src="/img/hackerman.png"
        alt="Gustavo Kuze desenho hackerman"
      />

      <WavySection>
        <section className={`bg-${theme.classes.panels} py-12`}>
          <div className="container max-w-5xl mx-auto mt-8">
            <h1
              className={`
                w-full my-2 text-5xl
                font-bold leading-tight
                text-center text-${theme.classes.sectionTitle}
                pt-5
              `}
            >
              {t('projects')}
            </h1>

            <WorksSection
              isLoading={isLoadingWorks}
              title={t('highlighted')}
              description=""
              works={(works.freelancer || []).filter(
                (work) => work.language === language,
              )}
              customDivider={
                <>
                  <div className="flex flex-row items-center justify-center lg:justify-end">
                    <a
                      href={getBlogHrefByLocale(
                        'https://blog.gustavokuze.com.br/projects',
                        language,
                      )}
                      className={`
                        shake-horizontal
                        text-right
                        text-${
                          theme.themeName === 'dark'
                            ? theme.classes.secondary
                            : theme.classes.primary
                        }
                        text-lg hover:text-${
                          theme.themeName === 'dark'
                            ? theme.classes.whiteyPrimary
                            : theme.classes.primaryStrong
                        }
                        transition-all duration-300 ease-linear
                        flex flex-row my-12
                      `}
                    >
                      <p className="text-right">{t('viewPortfolio')}</p>
                      <Icon
                        containerClass="mr-6"
                        color={`text-${
                          theme.themeName === 'dark'
                            ? theme.classes.secondary
                            : theme.classes.primary
                        }`}
                        size={5}
                        icon="chevron-right"
                      />
                    </a>
                  </div>
                  <hr className={`text-${theme.classes.whiteyPrimary}`} />
                </>
              }
            />
          </div>

          <WhatICanDo />
        </section>
      </WavySection>
      <Pricing />
      <Footer />

      <div className="whatsapp-widget">
        <a
          href="https://api.whatsapp.com/send?phone=5551999482237"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            analyticsEvent('click_whatsapp_widget');
          }}
        >
          <img
            src="/img/whatsapp.png"
            width="60"
            height="60"
            alt="Fale Conosco pelo WhatsApp"
          />
        </a>
      </div>
    </div>
  );
};

export default Home;
