import { call, put } from 'redux-saga/effects';
import { getWorks } from 'services/firebase/db';
import { worksTypes } from '../ducks/works';

function* fetchWorks() {
  yield put({ type: worksTypes.SET_IS_LOADING, payload: true });
  let works = {};

  try {
    works = yield call(() => getWorks());
    works = Object.fromEntries(
      Object.entries(works).map(([companyName, companyWorks]) => [
        companyName,
        companyWorks.filter((w) => !w.draft),
      ]),
    );
  } catch (err) {
    works = {};
  }

  yield put({
    type: worksTypes.SET_WORKS,
    payload: works,
  });
  yield put({ type: worksTypes.SET_IS_LOADING, payload: false });
}

export { fetchWorks };
